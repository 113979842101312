import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Hotel Booking Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/hotel-booking-web-app/"
    metaDescription="Working on your own hotel booking website design? Tripster is the perfect solution for streamlining your project. Sign up for Uizard today!"
    description="
    h2:Introducing… Tripster, a hotel booking website design template
    <br/>
    Working on your own hotel booking website design? Tripster is the perfect solution for streamlining your project. As with all our product design templates, Tripster is ready to go right out of the box. Simply sign up to Uizard Pro, select the template, invite your teammates, and get to work.
    <br/>
    h3:5-star design, every time
    <br/>
    Whether you use one of our pre-made <a:https://uizard.io/templates/>UI templates</a> or start your project from scratch, Uizard empowers you to create stunning app mockups every single time. Drag and drop components, create new screens, scan in your hand-drawn wireframes, and you can even import style elements from other sites with the URL alone.
    <br/>
    h3:Work alone or collaborate with your team
    <br/>
    Streamline the <a:https://uizard.io/prototyping/>UI prototyping</a> process like never before with Uizard's smart collaborations features. Share the link to your project so teammates can update and edit in real-time alongside you or share view-only access with external stakeholders or potential users - the possibilities are endless!
    "
    pages={[
      "A stunning home screen with hotel search functionality mockup and popular destinations list",
      "Location search screen with hotel listing and detail",
      "Hotel detail screen with images, location, and property overview",
      "Room listing screen with booking confirmation user flow demonstrated",
    ]}
    projectCode="ML6em8lmn3TzA8Vv1AK5"
    img1={data.image1.childImageSharp}
    img1alt="hotel booking web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="hotel booking web app design template hotel preview screen"
    img3={data.image3.childImageSharp}
    img3alt="hotel booking web app design template hotel search screen"
    img4={data.image4.childImageSharp}
    img4alt="hotel booking web app design template confirm screen"
    img5={data.image5.childImageSharp}
    img5alt="hotel booking web app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/hotel-booking-web-app/hotel-booking-web-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/hotel-booking-web-app/hotel-booking-web-preview.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/hotel-booking-web-app/hotel-booking-web-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/hotel-booking-web-app/hotel-booking-web-screen.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/hotel-booking-web-app/hotel-booking-web-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
